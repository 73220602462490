import { configureStore } from "@reduxjs/toolkit";
import templateSlice from './redux/slices/templateSlice'
import userSlice from './redux/slices/userSlice'
import companySlice from "./redux/slices/companySlice";


const store = configureStore({
  reducer: {
    template: templateSlice,
    user: userSlice,
    company: companySlice
  }
})
export default store
