import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: ""
}

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.isLoggedIn = true;
      state.id = action.payload;
    },
    setCompanyName: (state, action) => {
      state.isLoggedIn = true;
      state.name = action.payload;
    }
  },
});

export const { setCompany, setCompanyName } = companySlice.actions

export default companySlice.reducer;