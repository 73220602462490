import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { withAuthenticator, Flex, Authenticator,
  Grid,
  Image,
  useTheme,
  View,
  Heading,
  AmplifyChatbot,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from 'src/assets/brand/C-ace Logo.svg'
Amplify.configure(awsExports);


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const components = {
  
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={4}
            style={{ marginLeft: "12%"}}
          >
           { <img src={logo} height={80} alt="slide 1" /> }
          </Heading>
          
        </View>
      );
    },
  },
}


class App extends Component {
  render() {
    return (
      <>
      
      <Authenticator 
        //variation="modal"
        hideSignUp={true}
        components={components}
        border= "0px solid"
        style={{ backgroundColor: "#000", border: "0px solid", height: '100%', marginTop:'50%' }}
      >
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </Authenticator>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login1" name="Login Page" element={<Login />} />
          </Routes>
        </Suspense>
      </HashRouter>
      
      </>
    )
  }
}

export default App
 